<template>
  <q-dialog :ref="$options.name" persistent>
    <q-card>
      <q-carousel
        v-model="slide"
        animated
        navigation
        padding
        :arrows="false"
        control-color="gray"
        style="height: auto"
      >
        <q-carousel-slide :name="1">
          <q-card-section>
            <q-img
              src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
              class="q-mb-lg q-badge--rounded"
            />
            <h5>Welcome to {{ $store.state.branding.name }}</h5>
          </q-card-section>

          <q-card-section class="q-pt-none">
            We're glad to have you onboard. Let's take a quick tour of the
            dashboard to get you up and running.
          </q-card-section>
        </q-carousel-slide>

        <q-carousel-slide :name="2">
          <q-card-section>
            <q-img
              src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
              class="q-mb-lg q-badge--rounded"
            />
            <h5>Your performance and activity summarized</h5>
          </q-card-section>

          <q-card-section class="q-pt-none">
            The Overview tab provides a summary of all things related to your
            advertiser business. Here, you can view overall performance,
            inventory trends, account issues and more.
          </q-card-section>
        </q-carousel-slide>

        <q-carousel-slide :name="3">
          <q-card-section>
            <q-img
              src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
              class="q-mb-lg q-badge--rounded"
            />
            <h5>Create and view customized reports</h5>
          </q-card-section>

          <q-card-section class="q-pt-none">
            The Reporting tab gives you complete access to your data and
            performance. You can create and view real-time reports using a
            variety of different dimensions, metrics and filters.
          </q-card-section>
        </q-carousel-slide>

        <q-carousel-slide :name="4">
          <q-card-section>
            <q-img
              src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
              class="q-mb-lg q-badge--rounded"
            />
            <h5>Manage your accounts and sources</h5>
          </q-card-section>

          <q-card-section class="q-pt-none">
            The Accounts tab is where you'll manage your accounts and their
            connected inventory sources. You can alter an account's delivery
            status and apply new settings such as bid and budget. Also, take
            control of your inventory sources - editing import configurations,
            viewing import history and more to ensure your products stay up to
            date.
          </q-card-section>
        </q-carousel-slide>

        <q-carousel-slide :name="5">
          <q-card-section>
            <q-img
              src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
              class="q-mb-lg q-badge--rounded"
            />
            <h5>Oversee your inventory</h5>
          </q-card-section>

          <q-card-section class="q-pt-none">
            Keep an eye on your product inventory with the Products tab. View
            all products that are connected to the network and make
            product-level updates to bid, budget, availability and more.
          </q-card-section>
        </q-carousel-slide>

        <q-carousel-slide :name="6">
          <q-card-section>
            <q-img
              src="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
              class="q-mb-lg q-badge--rounded"
            />
            <h5>Administer your business</h5>
          </q-card-section>

          <q-card-section class="q-pt-none">
            Finally, the Admin settings can be found in the lower left corner of
            your dashboard. Here, you can manage access to your businesses'
            dashboard with the User Access tab and update your business
            information in the Business Settings tab.
          </q-card-section>
        </q-carousel-slide>
      </q-carousel>

      <q-card-actions align="between" class="q-gutter-x-md q-pa-md">
        <q-btn
          v-if="slide === 1"
          flat
          label="Skip Tour"
          color="subtle"
          v-close-popup
        />
        <q-btn v-else flat label="Back" color="subtle" @click="slide--" />
        <q-btn
          v-if="slide === 6"
          color="primary"
          label="Finish Tour"
          v-close-popup
        />
        <q-btn v-else label="Continue" color="primary" @click="slide++" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "AdvertiserWelcomeModal",
  props: {},
  data() {
    return {
      slide: 1
    };
  },
  methods: {
    show() {
      this.$refs[this.$options.name].show();
    },
    hide() {
      this.$refs[this.$options.name].hide();
    }
  }
};
</script>

<style scoped lang="scss"></style>
