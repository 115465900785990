<template>
  <div class="advertiser-account-selection-container">
    <q-breadcrumbs active-color="black">
      <template v-slot:separator>
        <q-icon size="1.5em" name="sym_r_chevron_right" color="primary" />
      </template>

      <!-- TODO: This may be only use-case, but if gonna be using these icons/names or whatever in places other than the sidebar, maybe ought to centralize so we can change icon all at once for example. -->
      <q-breadcrumbs-el
        label="Accounts"
        :icon="getAssetIcon('accounts')"
        :to="{ name: 'AdvertiserAccountsOverview' }"
      />
      <q-breadcrumbs-el />
    </q-breadcrumbs>

    <q-select
      v-if="advertiserAccountOptions.length > 0"
      borderless
      dense
      v-model="advertiserAccountId"
      :options="advertiserAccountOptions"
      emit-value
      map-options
      style="width: auto"
      @update:model-value="
        val =>
          $router.push({
            name: $route.name,
            params: { ...$route.params, advertiserAccountId: val }
          })
      "
      :disable="disable"
    >
      <template v-slot:selected-item="scope">
        {{ scope.opt.label }}
        <q-badge
          v-if="scope.opt.business_id != $store.state.business.record.id"
          color="gray-light"
          text-color="gray-medium-dark"
          :label="'Shared by ' + scope.opt.business_name"
          style="font-size: 11px"
          class="q-ml-sm"
        />
      </template>
      <template v-slot:before-options>
        <div style="position: sticky; top: 0; background: #fff; z-index: 1;">
          <q-item dense>
            <q-item-section avatar>
              <Label text="Accounts" />
            </q-item-section>
            <!-- TODO: Add search functionality -->
            <!--<q-item-section>
              <q-input
                v-if="advertiserAccountOptions.length > 10"
                dense
                filled
                type="search"
                debounce="600"
                v-model="advertiserAccountOptionsFilter"
                placeholder="Search..."
                class="self-stretch"
                @clear="
                  () => {
                    advertiserAccountOptionsFilter = '';
                  }
                "
              />
            </q-item-section>-->
          </q-item>
        </div>
      </template>
      <template v-slot:option="scope">
        <q-item v-bind="scope.itemProps" class="q-pr-xl">
          <q-item-section>
            <q-item-label>
              {{ scope.opt.label }}
              <q-badge
                v-if="scope.opt.business_id != $store.state.business.record.id"
                color="gray-light"
                text-color="gray-medium-dark"
                :label="'Shared by ' + scope.opt.business_name"
                style="font-size: 11px"
                class="q-ml-sm"
              />
            </q-item-label>
            <q-item-label caption>{{ scope.opt.value }}</q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>

<script>
import Label from "@/components/UI/Label";

export default {
  name: "AdvertiserAccountSelectionBreadcrumb",
  components: { Label },
  props: {
    disable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      advertiserAccountId: this.$route.params.advertiserAccountId ?? null,
      advertiserAccountOptions: [],
      advertiserAccountOptionsFilter: ""
    };
  },
  watch: {
    $route() {
      this.advertiserAccountId = this.$route.params.advertiserAccountId ?? null;
    }
  },
  mounted() {
    this.$store
      .dispatch("advertiserAccounts/GET_REQUEST", {})
      .then(advertiserAccounts => {
        if (advertiserAccounts.length > 0) {
          this.advertiserAccountOptions = this.generateSelectOptionsArray(
            advertiserAccounts,
            "name",
            "id",
            null,
            [
              {
                optionKey: "business_id",
                valueKey: "business_id"
              },
              {
                optionKey: "business_name",
                valueKey: "business_name"
              }
            ]
          );
        }
      });
  }
};
</script>

<style scoped lang="scss">
.advertiser-account-selection-container {
  // background: color(gray, light);
  @include display-flex(row, flex-start, center);
  background: #fff;
  padding: $space-sm $space-lg;
  // box-shadow: $shadow-default;
  border-bottom: 1px solid color(border);
  z-index: 1;
  position: relative;
  min-height: 56px;
}
</style>
