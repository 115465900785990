<template>
  <q-drawer
    dark
    show-if-above
    v-model="leftDrawerOpen"
    side="left"
    class="app-sidebar-drawer__content column bg-background-dark"
    behavior="desktop"
    :width="228"
    :mini-width="62"
    elevated
    :mini="leftDrawerMini"
    @click="$store.commit('setLeftDrawerMini', false)"
  >
    <q-list class="app-menu">
      <q-item
        to="/advertiser/overview"
        :active="$route.path.includes('/advertiser/overview')"
        :disable="$store.getters['business/advertiserInitialFlowRestricted']"
      >
        <q-item-section avatar>
          <q-avatar icon="sym_r_home" />
          <q-tooltip
            v-if="
              !$store.getters['business/advertiserInitialFlowRestricted'] &&
                leftDrawerMini
            "
            anchor="center right"
            self="center left"
          >
            Overview
          </q-tooltip>
        </q-item-section>
        <q-item-section>
          Overview
        </q-item-section>
        <q-tooltip
          v-if="$store.getters['business/advertiserInitialFlowRestricted']"
          anchor="center right"
          self="center left"
        >
          {{ accessRestrictedMessage("Overview") }}
        </q-tooltip>
      </q-item>

      <q-item
        to="/advertiser/accounts"
        :active="$route.path.includes('/advertiser/accounts')"
      >
        <q-item-section avatar>
          <q-avatar :icon="getAssetIcon('accounts')" />
          <q-tooltip
            v-if="
              !$store.getters['business/advertiserInitialFlowRestricted'] &&
                leftDrawerMini
            "
            anchor="center right"
            self="center left"
          >
            Accounts
          </q-tooltip>
        </q-item-section>
        <q-item-section>
          Accounts
        </q-item-section>
      </q-item>

      <q-item
        to="/advertiser/reporting"
        :active="$route.path.includes('/advertiser/reporting')"
        :disable="$store.getters['business/advertiserInitialFlowRestricted']"
      >
        <q-item-section avatar>
          <q-avatar :icon="getAssetIcon('reporting')" />
          <q-tooltip
            v-if="
              !$store.getters['business/advertiserInitialFlowRestricted'] &&
                leftDrawerMini
            "
            anchor="center right"
            self="center left"
          >
            Reporting
          </q-tooltip>
        </q-item-section>
        <q-item-section>
          Reporting
        </q-item-section>
        <q-tooltip
          v-if="$store.getters['business/advertiserInitialFlowRestricted']"
          anchor="center right"
          self="center left"
        >
          {{ accessRestrictedMessage("Reporting") }}
        </q-tooltip>
      </q-item>

      <!--<q-expansion-item expand-separator icon="sym_r_tune" label="Accounts">
        <q-item class="--inset-level-1" to="/advertiser/accounts" exact>
          <q-item-section>
            Overview
          </q-item-section>
        </q-item>
      </q-expansion-item>-->

      <q-item
        :to="'/advertiser/' + $store.state.productTypeConfigs.type + 's'"
        :active="$route.path.includes('/advertiser/' + $store.state.productTypeConfigs.type + 's')"
        :disable="$store.getters['business/advertiserInitialFlowRestricted']"
      >
        <q-item-section avatar>
          <q-avatar :icon="getAssetIcon($store.state.productTypeConfigs.type + 's')" />
          <q-tooltip
            v-if="
              !$store.getters['business/advertiserInitialFlowRestricted'] &&
                leftDrawerMini
            "
            anchor="center right"
            self="center left"
          >
            {{ $store.state.productTypeConfigs.typeFormatted + 's' }}
          </q-tooltip>
        </q-item-section>
        <q-item-section>
          {{ $store.state.productTypeConfigs.typeFormatted + 's' }}
        </q-item-section>
        <q-tooltip
          v-if="$store.getters['business/advertiserInitialFlowRestricted']"
          anchor="center right"
          self="center left"
        >
          {{ accessRestrictedMessage($store.state.productTypeConfigs.typeFormatted + 's') }}
        </q-tooltip>
      </q-item>

    </q-list>

    <q-space />

    <q-list class="app-menu" v-if="$store.state.user.record.type === 'admin'">
      <q-item />
      <q-expansion-item
        :model-value="adminExpanded"
        @update:model-value="val => (adminExpanded = val)"
        expand-separator
        :class="{
          'q-expansion-item--active': $route.path.includes('/advertiser/admin/')
        }"
      >
        <template v-slot:header>
          <q-item-section avatar>
            <q-avatar icon="sym_r_settings" />
            <q-tooltip
              v-if="
                !$store.getters['business/advertiserInitialFlowRestricted'] &&
                  leftDrawerMini
              "
              anchor="center right"
              self="center left"
            >
              Admin
            </q-tooltip>
          </q-item-section>
          <q-item-section>
            Admin
          </q-item-section>
        </template>

        <!--<q-item class="--inset-level-1" to="/advertiser/admin/billing" exact>
          <q-item-section>
            Billing
          </q-item-section>
        </q-item>-->
        <q-item
          class="--inset-level-1"
          to="/advertiser/admin/business-settings"
          exact
        >
          <q-item-section>
            Business Settings
          </q-item-section>
        </q-item>
        <q-item
          class="--inset-level-1"
          to="/advertiser/admin/user-access"
          exact
        >
          <q-item-section>
            User Access
          </q-item-section>
        </q-item>
      </q-expansion-item>
    </q-list>
  </q-drawer>
</template>

<script>
export default {
  name: "TheAdvertiserLeftDrawer",
  data() {
    return {
      miniState: true,
      adminExpanded: false
    };
  },
  computed: {
    leftDrawerOpen() {
      return this.$store.state.leftDrawerOpen;
    },
    leftDrawerMini() {
      return this.$store.state.leftDrawerMini;
    }
  },
  watch: {
    leftDrawerMini(val) {
      if (val) this.adminExpanded = false;
    }
  },
  methods: {
    accessRestrictedMessage(pageName) {
      return (
        "Create your first Advertiser Account to access the " +
        pageName +
        " tab."
      );
    }
  }
};
</script>

<style scoped lang="scss"></style>
